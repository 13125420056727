<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Language ID:">
        <el-input v-model="id" type="number" placeholder="please"></el-input>
      </el-form-item>
      <!-- <el-form-item label="Option:">
        <select-box
          :url="outright ? STD_SEARCH_OUTRIGHT_OPTION : STD_SEARCH_OPTION"
          :queryOrderParams="outright ? {languageType:'ENG', libraryType:15 } : { page: 1, size: 100 }"
          :value.sync="extraData.id"
          :search-key="outright?'text': 'optionName'"
          :label-key="outright ? 'i18nItems[0].text' : 'optionName'"
          :value-key="outright ? 'languageId' : 'id'"
          :disabled="id ? true : false"
        />
      </el-form-item> -->
      <el-form-item label="UnConfigure:">
        <el-select v-model="extraData.unConfigureLanguageType" clearable=""  placeholder="Select">
          <el-option v-for="item in langData" :key="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button type="success" @click="exportExcel({libraryType})" :loading="downloding">Export</el-button>
      </el-form-item>
    </el-form>

    <el-table
      border
      :data="list"
      stripe
      v-loading="loading"
      :max-height="tableHeight - fixHeight"
      @sort-change="sort"
    >
      <el-table-column
        label="Language ID"
        width="120"
        align="center"
        prop="languageId"
      ></el-table-column>
      <el-table-column
        v-for="item in langData"
        :key="item + 'header'"
        :label="item"
        min-width="120"
        align="center"
        prop="sportsName"
      >
        <template v-slot="{ row }">{{ row.language[item] }}</template>
      </el-table-column>
      <el-table-column
        label="Remark"
        width="120"
        align="center"
        prop="remark"
      ></el-table-column>
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link
            type="primary"
            :underline="false"
            @click="
              dialogEdit({
                id: row.id,
                nameCode: row.nameCode,
                language: row.language,
                remark: row.remark
              })
            "
            >Edit</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin-top: 20px">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>

    <el-dialog
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="120px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Option ID:" v-if="dialogForm.id">{{
          dialogForm.id
        }}</el-form-item>
        <el-form-item
          v-for="item in langData"
          :prop="`language${item}`"
          :key="item"
          :label="item + ':'"
        >
          <el-input
            v-if="dialogForm.language"
            v-model="dialogForm.language[item]"
            placeholder="Please typing in text"
            style="width:300px"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="Remark:">
          <el-input
            v-model="dialogForm.remark"
            placeholder="Please typing in text"
            style="width:300px"
            maxlength="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="dialog.isLoading" @click="submit"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  editInternational as editData,
  getAllLanguages 
} from '@/service/standard'
import {getMatchMappingList} from '@/service/subscribe'
import TableMixin from '@/mixins/standard/tableSelect'
export default {
  props: {
    libraryType: {
      type: String,
      default() {
        return ''
      }
    }
  },
  mixins: [TableMixin],
  computed: {
    outright() {
      return +this.libraryType === 15
    }
  },
  data() {
    return {
      extraData: {},
      id: ''
    }
  },
  inject: ['fixHeight', 'langData', 'rules'],
  methods: {
    getData(...rest) {
      console.log(rest)
      return new Promise(((resolve, reject) => {
        getMatchMappingList({"startTime":"","endTime":1643448869517,"dataSourceCodes":[2],orders: [{asc: true, column: "begin_time"}],"matchQueryType":6, isStage: 1,"sourceMatchId": (this.id? '2:' +this.id: ''),"matchType":1, ...rest[0]}).then(res => {
          console.log(res)
          if(res.code === 0) {
            const list = res.data.records || []
            this.total = res.data.total
            getAllLanguages(list.map(item => {
              return {
                libraryType: this.libraryType,
                languageId: /2:(\d+)/.exec(item.sourceMatchId)[1]
              }
            })).then(res => {
              if(res.code === 0) {
                resolve(res)
              } else {
                reject(res)
              }
            })
          } else {
            reject(res)
          }
        })
      }))
      // return this.outright ? OutrightOption(...rest): getData(...rest)
    },
    editData,
    submit() {
      const language = this.dialogForm.language
      this.dialogForm.json = JSON.stringify(language)
      this.dialogForm.libraryType = this.libraryType

      this.dialogSubmit(this.dialogForm.language)
    },
    getList() {
      this.loading = true
      if(this.outright) {
        this.extraData.libraryType = this.libraryType
      }
        let extraData = {...this.extraData}
      if (this.id) {
        delete this.extraData.id
        extraData.id = +this.id
        extraData = this.fliterParams(extraData, ['id', 'libraryType'])
      }
      this.getData({ ...this.queryData, ...extraData })
        .then(res => {
          const data = res.data
          if (res.code === 0) {
            data.map(item => {
              const leng  ={}
              item.i18nItems.map(l => {
                leng[l.languageType] = l.text
                if(l.languageType === 'ENG') {
                  item.remark = l.remark
                }
              })
              item.language =  leng
            })
            this.list = data
           
            this.loading = false
          }
        })
        .catch(err => {
          this.list = []
          this.loading = false
        })
    }
  }
}
</script>
